import dayjs from "dayjs";

export const getDateString = (value: Date) => {
  return dayjs(value).format("DD MMMM YYYY");
};

export const getMonthString = (month: number, isShort?: boolean) => {
  const shortMonths = [
    "Oc",
    "Şu",
    "Mar",
    "Nis",
    "May",
    "Haz",
    "Tem",
    "Agu",
    "Ey",
    "Ek",
    "Kas",
    "Ara",
  ];

  const longMonths = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  month -= 1;
  return isShort ? shortMonths[month] : longMonths[month];
};

export const isImageMedia = (extension: string) => {
  return (
    extension === "png" ||
    extension === "PNG" ||
    extension === "JPEG" ||
    extension === "jpeg" ||
    extension === "jpg" ||
    extension === "JPG"
  );
};

export const mathRound = (value: number, precision?: number) => {
  const precisionValue = Math.pow(10, precision ?? 3);
  return Math.round(value * precisionValue) / precisionValue;
};

export const isDateBeforeToday = (date: Date) => {
  return dayjs().isSame(date, "day") || dayjs().isAfter(date, "day");
};

export const cellStyle = {
  border: "1px solid black",
  padding: "1em",
};

export const formItemStyle = {
  margin: 0,
};

export const stringNumberSorter = (a: any, b: any, fieldName: string) => {
  if (typeof a[fieldName] == "number" && typeof b[fieldName] == "string")
    return -1;
  if (typeof a[fieldName] == "string" && typeof b[fieldName] == "number")
    return 1;
  if (typeof a[fieldName] == "string" && typeof b[fieldName] == "string")
    return 0;

  return a[fieldName] - b[fieldName];
};
