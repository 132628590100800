import SidebarLayout from "../core/layout/SidebarLayout";
import { getMenuItems } from "../navigation/Navigations";
import { LogoutOutlined } from "@ant-design/icons";
import HeaderBadge from "../core/component/HeaderBadge";
import { AuthHelper } from "../core/helper/AuthHelper";
import OwnNotifications from "../components/notification/OwnNotifications";
import AllNotifications from "../components/notification/AllNotifications";

const Layout = (props: any) => {
  return (
    <SidebarLayout
      title={
        <img
          width={70}
          height={40}
          alt="logo"
          src="https://www.logomaker.com/api/main/images/1j+ojVVCOMkX9Wyrexe4hGfey7XE8wETmhPbi2c...ZzlJ8gtpmSEpjuQuq6R6b0gN4FcNgRwPe880jjIqD99LxUc6unyAOA=="
        />
      }
      navigations={getMenuItems()}
      extra={(isSmallScreen: any) => (
        <>
          {AuthHelper.checkRole("ROLE_ADMIN") && (
            <AllNotifications isSmallScreen={isSmallScreen} />
          )}
          <OwnNotifications isSmallScreen={isSmallScreen} />
          <HeaderBadge
            tooltipText="Çıkış Yap"
            avatarIcon={<LogoutOutlined />}
            onClick={() => AuthHelper.logout()}
          />
        </>
      )}
    >
      {props.children}
    </SidebarLayout>
  );
};

export default Layout;
