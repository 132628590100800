import { Input } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

interface ISearchBarProps {
  size?: SizeType;
  width?: number;
  entities: any[];
  searchKeys: string[];
  placeholder?: string;
  setFilteredEntities: (entities: any) => void;
}

const SearchBar = (props: ISearchBarProps) => {
  const search = (value: string) => {
    if (!value || props.searchKeys.length === 0) {
      props.setFilteredEntities(props.entities);
      return;
    }

    let filteredData = [] as any[];
    props.searchKeys.forEach((key: string) => {
      props.entities.forEach((entity: any) => {
        const searchField = entity[key].toString();
        if (!searchField) return false;
        const isFinded = searchField
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase());
        if (isFinded && filteredData.indexOf(entity) === -1) {
          filteredData.push(entity);
        }
      });
    });
    props.setFilteredEntities(filteredData);
  };

  return (
    <Input.Search
      style={{ width: (props.width ?? 100) + "%" }}
      allowClear
      size={props.size ?? "large"}
      placeholder={props.placeholder ?? "Aramak için yazınız"}
      enterButton
      onSearch={search}
    />
  );
};

export default SearchBar;
