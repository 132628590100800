import CrudTable from "../core/component/crud/CrudTable";
import {
  getUserCPColumns,
  getUserCPFormItems,
} from "../components/crud/UserCPComponents";
import { userCrudApi } from "../core/client/BaseEntityApi";
import { useEffect, useState } from "react";
import { userCrudExtendedApi } from "../client/EntityApi";

const UserCrudPage = () => {
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    userCrudExtendedApi.getAllRoles().then((response) => {
      const mappedRoles = response["hydra:member"].map((role: any) => {
        return { label: role.name, value: role["@id"] };
      });
      setRoles(mappedRoles);
    });
  }, []);

  return (
    <CrudTable
      api={userCrudApi}
      entityLabel="Kullanıcı"
      searchKeys={["name", "surname"]}
      columns={getUserCPColumns()}
      addFormItems={getUserCPFormItems(roles, true)}
      editFormItems={getUserCPFormItems(roles)}
      setEditFields={(row) => {
        return {
          name: row.name,
          surname: row.surname,
          username: row.username,
          isPassive: row.isPassive,
          role: row.role["@id"],
        };
      }}
      beforeEditOperation={(values) => {
        if (values.plainPassword === "") values.plainPassword = undefined;
        return values;
      }}
    />
  );
};

export default UserCrudPage;
