import YesNoTag from "../../core/component/YesNoTag";
import { cellStyle } from "../../core/helper/UtilHelper";

interface IRecipeExpandedTableProps {
  recipe: any;
}

const RecipeExpandedTable = (props: IRecipeExpandedTableProps) => {
  return (
    <table
      style={{
        width: "50%",
        textAlign: "center",
        borderCollapse: "collapse",
        border: "1px solid black",
        margin: "1em 0px 1em 0px",
      }}
    >
      <tr style={cellStyle}>
        <th style={cellStyle}>HAMMADDE ADI</th>
        <th style={cellStyle}>HAMMADDE STOK MİKTARI</th>
        <th style={cellStyle}>KULLANILACAK BİRİM MİKTAR</th>
        <th style={cellStyle}>KAÇ ADET ÜRETİLEBİLİR</th>
      </tr>
      {props.recipe.recipeMaterials.map((recipeMaterial: any) => {
        if (!recipeMaterial.material) return <></>;
        const generatableCount = Math.floor(
          recipeMaterial.material.quantity / recipeMaterial.quantity
        );
        return (
          <tr style={cellStyle}>
            <td style={cellStyle}>{recipeMaterial.material.name}</td>
            <td style={cellStyle}>{recipeMaterial.material.quantity}</td>
            <td style={cellStyle}>{recipeMaterial.quantity}</td>
            <td style={cellStyle}>
              <YesNoTag
                success={generatableCount > 0}
                successText={generatableCount.toString()}
                failText={generatableCount.toString()}
              />
            </td>
          </tr>
        );
      })}
    </table>
  );
};

export default RecipeExpandedTable;
