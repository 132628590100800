import { Avatar, Badge, Tooltip } from "antd";
import { ReactNode } from "react";

interface IHeaderBadgeProps {
  avatarIcon?: any;
  avatarText?: ReactNode | string;
  tooltipText?: string;
  hideBadge?: boolean;
  badgeCount?: number | string;
  onClick?: () => void;
}

const HeaderBadge = (props: IHeaderBadgeProps) => {
  return (
    <a
      href="!#"
      onClick={(e) => {
        e.preventDefault();
        if (props.onClick) props.onClick();
      }}
    >
      <Tooltip title={props.tooltipText}>
        <Badge
          count={props.badgeCount}
          style={{ color: "white", backgroundColor: "red", borderColor: "red" }}
          offset={[-13, 1]}
        >
          <Avatar
            style={{ color: "white", backgroundColor: "#3f35b5" }}
            icon={props.avatarIcon}
          >
            {props.avatarText}
          </Avatar>
        </Badge>
      </Tooltip>
    </a>
  );
};

export default HeaderBadge;
