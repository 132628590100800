import { Button, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { mathRound } from "../../core/helper/UtilHelper";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Rules } from "../../core/Rules";

export const getRecipeCPColumns = (currencyRates: any) => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Reçete Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Reçete Kodu",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Reçetenin Güncel Maliyeti (TL)",
      dataIndex: "stockPrice",
      render: (text: any, row: any) => {
        let recipePrice = 0;
        row.recipeMaterials.forEach((recipeMaterial: any) => {
          if (!recipeMaterial.material) return;
          recipePrice +=
            recipeMaterial.quantity *
            recipeMaterial.material?.unit *
            (currencyRates[recipeMaterial.material?.currency] ?? 1);
        });
        return mathRound(recipePrice);
      },
    },
  ];
};

export const getRecipeCPFormItems = (mappedMaterials: any[]) => {
  return (
    <>
      <Form.Item
        label="Reçete Adı"
        name="name"
        rules={[Rules.required, Rules.min(2), Rules.max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Reçete Kodu"
        name="code"
        rules={[Rules.required, Rules.max(25)]}
      >
        <Input />
      </Form.Item>
      <Form.List name="recipeMaterials">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name }, index) => (
              <Row
                key={index}
                style={{
                  border: "1px dashed black",
                  margin: "1em 0px 1em 0px",
                  padding: "1em",
                }}
              >
                <Col span={24}>
                  <Row style={{ marginBottom: "1em" }}>
                    <Col span={20}>
                      <Row justify="center">Hammadde</Row>
                    </Col>
                    <Col span={4}>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                  <Form.Item
                    label="Hammadde"
                    name={[name, "material"]}
                    rules={[Rules.required]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      options={mappedMaterials}
                      filterOption={(input, option) =>
                        (option?.label as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Miktar"
                    name={[name, "quantity"]}
                    rules={[Rules.required]}
                    initialValue={"0"}
                  >
                    <InputNumber
                      min={0}
                      precision={3}
                      decimalSeparator=","
                      stringMode
                    />
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Hammadde Ekle
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};
