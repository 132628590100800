import { Drawer, Row, Col, Button, Modal, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { notificationCrudExtendedApi } from "../../client/EntityApi";
import DatePick from "../../core/component/DatePick";
import HeaderBadge from "../../core/component/HeaderBadge";
import { AuthHelper } from "../../core/helper/AuthHelper";
import { isDateBeforeToday } from "../../core/helper/UtilHelper";
import { Rules } from "../../core/Rules";
import NotificationCard from "./NotificationCard";
import { LoadingOutlined } from "@ant-design/icons";

interface IOwnNotificationsProps {
  isSmallScreen?: boolean;
}

const OwnNotifications = (props: IOwnNotificationsProps) => {
  const [notificationAddForm] = Form.useForm();

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [isTodayNotification, setIsTodayNotification] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [ownNotifications, setOwnNotifications] = useState([]);
  const [ownTodayNotifications, setOwnTodayNotifications] = useState([]);

  const [addNotificationModalIsOpen, setAddNotificationModalIsOpen] =
    useState(false);

  useEffect(() => {
    getNotifications();
    // const notificationInterval = setInterval(getNotifications, 60000);
    // return () => clearInterval(notificationInterval);
  }, []);

  useEffect(() => {
    setOwnTodayNotifications(
      ownNotifications.filter(
        (notification: any) =>
          isDateBeforeToday(new Date(notification.notificationDate)) &&
          !notification.readDate
      )
    );
  }, [ownNotifications]);

  const getNotifications = () => {
    setNotificationLoading(true);
    notificationCrudExtendedApi
      .getOwnNotifications()
      .then((response) => setOwnNotifications(response["hydra:member"]))
      .finally(() => setNotificationLoading(false));
  };

  const onCancelNotificationAddModal = () => {
    notificationAddForm.resetFields();
    setAddNotificationModalIsOpen(false);
  };

  return (
    <>
      <HeaderBadge
        tooltipText="Bildirimlerim"
        avatarText={
          AuthHelper.getUserField("name").substring(0, 1) +
          AuthHelper.getUserField("surname").substring(0, 1)
        }
        badgeCount={ownTodayNotifications.length}
        onClick={() => setDrawerIsOpen(true)}
      />
      <Drawer
        title={<>Bildirimlerim</>}
        placement="right"
        visible={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
        width={props.isSmallScreen ? "300px" : "500px"}
      >
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Button
              style={{ width: "100%" }}
              onClick={() => setAddNotificationModalIsOpen(true)}
            >
              Bildiri Ekle
            </Button>
          </Col>
          <Col span={12}>
            <Button
              style={{ width: "100%" }}
              onClick={() => setIsTodayNotification(!isTodayNotification)}
            >
              {isTodayNotification ? "Tümünü Göster" : "Bugünü Göster"}
            </Button>
          </Col>
        </Row>

        {notificationLoading ? (
          <Row>
            <Col span={24}>
              <Row justify="center" align="middle" style={{ margin: "5em" }}>
                <LoadingOutlined spin />
              </Row>
            </Col>
          </Row>
        ) : (
          (isTodayNotification ? ownTodayNotifications : ownNotifications).map(
            (notification, index) => {
              return (
                <NotificationCard
                  key={index}
                  notification={notification}
                  updateList={getNotifications}
                />
              );
            }
          )
        )}
      </Drawer>
      <Modal
        title="Bildiri Oluştur"
        visible={addNotificationModalIsOpen}
        onOk={() => {
          notificationAddForm.validateFields().then((values) => {
            notificationCrudExtendedApi
              .create(values, true)
              .then(() => getNotifications())
              .finally(() => onCancelNotificationAddModal());
          });
        }}
        onCancel={() => onCancelNotificationAddModal()}
        okText="Oluştur"
        cancelText="Vazgeç"
      >
        <Form form={notificationAddForm} layout="vertical">
          <Form.Item
            label="Başlık"
            name="title"
            rules={[Rules.required, Rules.min(3), Rules.max(100)]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Açıklama"
            name="description"
            rules={[Rules.max(255)]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            label="Uyarı Tarihi"
            name="notificationDate"
            rules={[Rules.required]}
          >
            <DatePick />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default OwnNotifications;
