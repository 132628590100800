import { Image } from "antd";
import Link from "antd/lib/typography/Link";
import { priceCrudApi } from "../client/EntityApi";
import {
  getPriceCPColumns,
  getPriceCPFormItems,
} from "../components/crud/PriceCPComponents";
import CrudTable from "../core/component/crud/CrudTable";
import { AuthHelper } from "../core/helper/AuthHelper";
import { isImageMedia } from "../core/helper/UtilHelper";

const PriceCrudPage = () => {
  return (
    <CrudTable
      entityLabel="Fiyat"
      api={priceCrudApi}
      searchKeys={["name"]}
      columns={getPriceCPColumns()}
      addFormItems={getPriceCPFormItems()}
      editFormItems={getPriceCPFormItems()}
      hideAddProcess={
        !(
          AuthHelper.checkRole("ROLE_ADMIN") ||
          AuthHelper.checkRole("ROLE_BOOKKEEPER")
        )
      }
      hideEditProcess={
        !(
          AuthHelper.checkRole("ROLE_ADMIN") ||
          AuthHelper.checkRole("ROLE_BOOKKEEPER")
        )
      }
      hideDeleteProcess={
        !(
          AuthHelper.checkRole("ROLE_ADMIN") ||
          AuthHelper.checkRole("ROLE_BOOKKEEPER")
        )
      }
      expandable={{
        expandedRowRender: (record: any) => (
          <>
            {record.image && isImageMedia(record.image.extension) ? (
              <Image
                width={200}
                src={process.env.REACT_APP_BASE_URL + record.image.uid}
              />
            ) : (
              <Link
                target="_blank"
                href={process.env.REACT_APP_BASE_URL + record.image.uid}
              >
                {record.image.name}
              </Link>
            )}
          </>
        ),
      }}
    />
  );
};

export default PriceCrudPage;
