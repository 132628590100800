import {
  SolutionOutlined,
  CreditCardOutlined,
  InfoCircleOutlined,
  ExperimentOutlined,
  // BankOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ReactNode } from "react";
import { Link, Route } from "react-router-dom";
import { AuthHelper } from "../core/helper/AuthHelper";
import Layout from "../layout/Layout";
import BusinessTasksCrudPage from "../pages/BusinessTasksCrudPage";
// import CompanyCrudPage from "../pages/CompanyCrudPage";
// import CompanyDetail from "../pages/CompanyDetail";
// import CPeriodicTasksCrudPage from "../pages/CPeriodicTasksCrudPage";
import GeneratedRecipeCrudPage from "../pages/GeneratedRecipeCrudPage";
import MaterialCrudPage from "../pages/MaterialCrudPage";
// import MyPeriodicTasksCrudPage from "../pages/MyPeriodicTasksCrudPage";
import NotificationCrudPage from "../pages/NotificationCrudPage";
import OrderCrudPage from "../pages/OrderCrudPage";
// import PerformanceReportCrudPage from "../pages/PerformanceReportCrudPage";
// import PeriodCrudPage from "../pages/PeriodCrudPage";
import PriceCrudPage from "../pages/PriceCrudPage";
import ProfilePage from "../pages/ProfilePage";
import RecipeCrudPage from "../pages/RecipeCrudPage";
// import UsageDocument from "../pages/UsageDocument";
import UserCrudPage from "../pages/UserCrudPage";
// import VaccineCrudPage from "../pages/VaccineCrudPage";
// import TaskApproveCrudPage from "../pages/TaskApproveCrudPage";

export const getPrivateRoutes = () => {
  return (
    <>
      <Route index element={<Layout />} />
      {/* {getRoute(<CPeriodicTasksCrudPage />, "/aylik-isletme-gorevleri")}
      {getRoute(<MyPeriodicTasksCrudPage />, "/aylik-gorevlerim")}
      {getRoute(<PerformanceReportCrudPage />, "/performans-raporu")}
      {getRoute(<CompanyCrudPage />, "/isletmeler")}
      {getRoute(<CompanyDetail />, "/isletme-detay/:id")}
      {getRoute(<TaskApproveCrudPage />, "/gorev-onay")}
      {getRoute(<UsageDocument />, "/kullanim-dokumani")} */}
      {getRoute(<BusinessTasksCrudPage />, "/idari-gorevler")}
      {getRoute(<MaterialCrudPage />, "/hammaddeler")}
      {getRoute(<RecipeCrudPage />, "/receteler")}
      {getRoute(<GeneratedRecipeCrudPage />, "/uretim-gecmisi")}
      {getRoute(<PriceCrudPage />, "/fiyat-listesi")}
      {getRoute(<OrderCrudPage />, "/siparis-listesi")}
      {getRoute(<NotificationCrudPage />, "/ajanda")}
      {/* {getRoute(<PeriodCrudPage />, "/donem")} */}
      {/* {getRoute(<VaccineCrudPage />, "/asi")} */}
      {getRoute(<UserCrudPage />, "/kullanici-yonetimi")}
      {getRoute(<ProfilePage />, "/profil")}
    </>
  );
};

const getRoute = (component: ReactNode, path: string) => {
  return <Route path={path} element={<Layout>{component}</Layout>} />;
};

export const getMenuItems = () => {
  let items: any[] = [
    // {
    //   label: "İş / İşletme",
    //   key: "is-isletme",
    //   icon: <BankOutlined />,
    //   children: [
    //     {
    //       label: "Raporlar",
    //       key: "raporlar",
    //       children: [
    //         {
    //           label: (
    //             <Link to="/aylik-isletme-gorevleri">
    //               Aylık İşletme Görevleri
    //             </Link>
    //           ),
    //           key: "aylik-isletme-gorevleri",
    //         },
    //         {
    //           label: <Link to="/aylik-gorevlerim">Aylık Görevlerim</Link>,
    //           key: "aylik-gorevlerim",
    //         },
    //         {
    //           label: <Link to="/performans-raporu">Performans Raporu</Link>,
    //           key: "performans-raporu",
    //         },
    //       ],
    //     },
    //     { label: <Link to="/isletmeler">İşletmeler</Link>, key: "isletmeler" },
    //     { label: <Link to="/gorev-onay">Görev Onay</Link>, key: "gorev-onay" },
    //     {
    //       label: <Link to="/kullanim-dokumani">Kullanım Dökümanı</Link>,
    //       key: "kullanim-dokumani",
    //     },
    //   ],
    // },
    {
      label: "İdari",
      key: "idari",
      icon: <InfoCircleOutlined />,
      children: [
        {
          label: <Link to="/idari-gorevler">İdari Görevler</Link>,
          key: "idari-gorevler",
        },
      ],
    },
    {
      label: "Üretim",
      key: "uretim",
      icon: <ExperimentOutlined />,
      children: [
        {
          label: <Link to="/hammaddeler">Hammaddeler</Link>,
          key: "hammaddeler",
        },
        { label: <Link to="/receteler">Reçeteler</Link>, key: "receteler" },
        {
          label: <Link to="/uretim-gecmisi">Üretim Geçmişi</Link>,
          key: "uretim-gecmisi",
        },
      ],
    },
    {
      label: "Sipariş",
      key: "siparis",
      icon: <CreditCardOutlined />,
      children: [
        {
          label: <Link to="/fiyat-listesi">Fiyat Listesi</Link>,
          key: "fiyat-listesi",
        },
        {
          label: <Link to="/siparis-listesi">Sipariş Listesi</Link>,
          key: "siparis-listesi",
        },
      ],
    },
  ];

  if (AuthHelper.checkRole("ROLE_ADMIN")) {
    items.push({
      label: "Yönetici",
      key: "yonetici",
      icon: <SolutionOutlined />,
      children: [
        { label: <Link to="/ajanda">Ajanda</Link>, key: "ajanda" },
        // { label: <Link to="/donem">Dönem</Link>, key: "donem" },
        // { label: <Link to="/asi">Aşı</Link>, key: "asi" },
        {
          label: <Link to="/kullanici-yonetimi">Kullanıcı Yönetimi</Link>,
          key: "kullanici-yonetimi",
        },
      ],
    });
  }

  items.push({
    label: "Hesap Yönetimi",
    key: "hesap-yonetimi",
    icon: <UserOutlined />,
    children: [
      {
        label: <Link to="/profil">Profil</Link>,
        key: "profil",
      },
      {
        label: <span onClick={AuthHelper.logout}>Çıkış Yap</span>,
        key: "cikis",
      },
    ],
  });

  return items;
};
