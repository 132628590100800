import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import {
  getOrderCPColumns,
  getOrderCPFormItems,
} from "../components/crud/OrderCPComponents";
import { orderCrudApi, orderExtendedApi } from "../client/EntityApi";
import { Modal, Typography } from "antd";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import { AuthHelper } from "../core/helper/AuthHelper";

const OrderCrudPage = () => {
  const { Title, Paragraph } = Typography;

  const [selectedId, setSelectedId] = useState<number>();
  const [deliverModalIsOpen, setDeliverModalIsOpen] = useState<boolean>(false);
  const [cancelDeliverModalIsOpen, setCancelDeliverModalIsOpen] =
    useState<boolean>(false);
  const crudPageRef = useRef<ICrudTableRefMethods>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onCancel = () => {
    setSelectedId(undefined);
    setDeliverModalIsOpen(false);
    setCancelDeliverModalIsOpen(false);
    setLoading(false);
  };

  return (
    <>
      <CrudTable
        ref={crudPageRef}
        entityLabel="Sipariş"
        api={orderCrudApi}
        searchKeys={["name"]}
        columns={getOrderCPColumns()}
        addFormItems={getOrderCPFormItems()}
        editFormItems={getOrderCPFormItems()}
        hideAddProcess={!AuthHelper.checkRole("ROLE_ADMIN")}
        hideEditProcess={!AuthHelper.checkRole("ROLE_ADMIN")}
        hideDeleteProcess={!AuthHelper.checkRole("ROLE_ADMIN")}
        expandable={{
          expandedRowRender: (record: any) => (
            <Typography>
              <Title level={5}>Sipariş Detayı</Title>
              <Paragraph> {record.orderDetail}</Paragraph>
              <Title level={5}>Sipariş Açıklaması</Title>
              <Paragraph> {record.description}</Paragraph>
            </Typography>
          ),
        }}
        extraRowProcess={(row: any) => {
          return (
            AuthHelper.checkRole("ROLE_ADMIN") && (
              <>
                {row.state !== "done" && (
                  <CrudPageProcessButton
                    tooltipText="Siparişi Onayla"
                    icon={<CheckOutlined />}
                    onClick={() => {
                      setSelectedId(row.id);
                      setDeliverModalIsOpen(true);
                    }}
                  />
                )}
                {row.state === "done" && (
                  <CrudPageProcessButton
                    tooltipText="Sipariş Onayını İptal Et"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      setSelectedId(row.id);
                      setCancelDeliverModalIsOpen(true);
                    }}
                  />
                )}
              </>
            )
          );
        }}
      />
      <Modal
        maskClosable={false}
        forceRender
        visible={deliverModalIsOpen}
        title={"Siparişi Onayla"}
        onOk={() => {
          if (selectedId) {
            setLoading(true);
            orderExtendedApi
              .deliverOrder(selectedId)
              .then(() => crudPageRef.current?.refreshData())
              .finally(() => onCancel());
          }
        }}
        onCancel={onCancel}
        okText="Siparişi Onayla"
        cancelText="Vazgeç"
        confirmLoading={loading}
      >
        Onay sonrasında sipariş üzerinde düzenleme yapılamayacaktır. Siparişi
        onaylamak istiyor musunuz ?
      </Modal>
      <Modal
        maskClosable={false}
        forceRender
        visible={cancelDeliverModalIsOpen}
        title={"Sipariş Onayını İptal Et"}
        onOk={() => {
          if (selectedId) {
            setLoading(true);
            orderExtendedApi
              .cancelDeliverOrder(selectedId)
              .then(() => crudPageRef.current?.refreshData())
              .finally(() => onCancel());
          }
        }}
        onCancel={onCancel}
        okText="Sipariş Onayını İptal Et"
        cancelText="Vazgeç"
        confirmLoading={loading}
      >
        Sipariş onayını iptal etmeniz durumunda siparişin durumu "Teslim
        Edilmedi" konumuna çekilecek, "Siparişi Onaylayan" ve "Siparişin Onay
        Tarihi" alanları sıfırlanacaktır. İptal etmek istiyor musunuz ?
      </Modal>
    </>
  );
};

export default OrderCrudPage;
